.notification {
  position: fixed;
  top: 10px;
  left: 6%;
  z-index: 1;
  background-color: white;
  font-weight: bold;
}

.infoMsg {
  color: green;
}

.errorMsg {
  color: red;
}

.primaryButton {
  background: #24a0ed;
  color: white;
}

.ownerInput input {
  width: 95%;
  margin: 0px;
}